const parseArrayParam = (searchStr) => {
  if(searchStr){
    const values = searchStr.split(',').map((value) => Number(value));
    if(values && values.length){
      return values
    } else {
      return []
    }
  }
  return []
}

export {parseArrayParam}