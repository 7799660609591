import { useState, useEffect } from "react"

import { Container } from '@mui/material';
// import { Link } from "react-router-dom";
// import { Blocks } from 'react-loader-spinner'

import { useData } from "../contexts/data-context"
import { useCalculate } from "../contexts/fib-context"

import './FilterCard.scss'
import FilterForm from "./FilterForm"

const FilterCard = () => {
  const {
    state: {fibNum, srcNum, loading},
    dispatch,
    runWorker
  } = useCalculate()  

  const {
    state: {selectedCount},
    getDinText
  } = useData()

  const [dinText, setDinText] = useState([])
  useEffect(() => {
    if(selectedCount){
      setDinText(getDinText(0, 10))
    }
  }, [selectedCount])
  

  const handleClick = () => {
    // Pick random integer to get fib sequence number for
    const num = Math.round(31+(Math.random() * 14));
    runWorker(num)
  }

  
  const rows = [];
  for (let i = 0; i < 10; i++) {
      // note: we are adding a key prop here to allow react to uniquely identify each
      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
      rows.push(<li key={i}>{dinText[i]}</li>);
  }

  return(
    <div className="FilterCard">
      <h1>Google Din Tool | GloYo</h1>
      <Container>
        <FilterForm />
      </Container>
      
      <ul>
        {rows}
      </ul>
      <br/>
{/*       
      <Button variant="primary" onClick={() => {handleClick()}} >Run Worker</Button>
      {srcNum &&
        <div>Fib sequence number: {srcNum}<br/>
        { loading ? 
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        : 
          <>
            { fibNum && <>{fibNum}</>}
          </>
        }
        </div>
      }
      <Link
        style={{ display: "block", margin: "1rem 0" }}
        to={`/explore/`}
      >
        Explore
      </Link> */}
    </div>
  )
}

export default FilterCard