import { styled } from "@mui/system";



const TitleSpan = styled('div')`
  font-family: "Roboto Mono Regular", monospace, sans-serif;
  font-size: .75em;
`;

export default function Title ({children}) {
  return (
    <TitleSpan>{children}</TitleSpan>
  )
}