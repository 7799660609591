import { borderRadius, styled } from '@mui/system';
import { Paper } from "@mui/material"
import Background from './data-gestalt/Background';

const SideBarContainer = styled(Paper)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  width: '200px',
  maxHeight: 'calc(100%-0.5em)',
  padding: '1.5em 1.5em',
  top: '0px',
  background: 'black',
  borderRadius: '0 0 30px 0',
  zIndex:1,
  color: 'white',
  pointerEvents: 'all',

  // inverted rounded border
  '&:before': {
    content: '""',
    position: 'absolute',
    backgroundColor: 'transparent',
    top: '0px',
    left: 'calc(100%)',
    height: '30px;',
    width: '60px',
    // borderTopLeftRadius: '30px',
    borderTopLeftRadius: '30px',
    boxShadow: '-1.5em 0 0 0 #000000'
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    zIndex:0,
    backgroundColor: 'transparent',
    // backgroundColor: 'white',
    top: '100%',
    left: '0',
    height: '60px;',
    width: '30px',
    borderTopLeftRadius: '30px',
    // borderTopLeftRadius: '30px',
    boxShadow: '0 -1.5em 0 0 #000000'
  }
})

export default function SideBar({children}) {
  return (
    <SideBarContainer>
      {children}
    </SideBarContainer>
  )
}