import { useEffect, useState } from "react";
import { Outlet, useNavigation, useLocation } from "react-router-dom";
import _ from 'lodash';

import Background from "../components/data-gestalt/Background"
import NavBar from "../components/NavBar";
import { useData, initialFilterForm } from "../contexts/data-context";
import { parseArrayParam } from "../helpers/search-queries";
import { Container } from "@mui/material";
// import { searchParamsToObject } from "../utils/urlUtils";
import styled from "@emotion/styled";
/*import {
  useQueryParams,
  StringParam,
  ArrayParam
} from 'use-query-params'*/
import { useURLSearchParams } from "../contexts/url-context";
import { getDefinedSearchParams } from '../utils/urlUtils'

export async function rootLoader({request , params}){
  // URLSearchParams()
  console.debug('run rootLoader', request, params);
  const searchParams = new URL(request.url).searchParams;
  const ageRange = parseArrayParam(searchParams.get("ageRange"));
  const countries = parseArrayParam(searchParams.get("countries"));
  const tagId = searchParams.get("tagId");
  const filterQuery = {
    ...initialFilterForm,
    countries: countries,
    ageRange: ageRange
  }
  console.debug(filterQuery);
  return {
    data: filterQuery
  };
}

const RootDiv = styled.div`
  background: black;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
`

export default function Root() {
  // let [searchParams, setSearchParams] = useSearchParams();
  const {search, setSearchParams, setInitialParams} = useURLSearchParams();
  
  const {
    state: { preloaded, loaded, loading, filterParams },
    setFilters, setTag, setInitialized
  } = useData()
  const [dataLoaded, setDataLoaded] = useState(false);
  // const countries = parseArrayParam(searchParams.get("countries"));
  const navigation = useNavigation();
  const location = useLocation();
  // const [search, setSearch] = useQueryParams({
  // })

  // searchParams is a URLSearchParams object.
  // See https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
  

  
  useEffect(() => {
    /**
     * On initial load, extract search parameters and apply
     * filters and select tag by id.
     */
    if(loaded){
      console.debug("Initial root mount");
      
      /*
      // only set filters if any exist in the search params
      const definedParams = getDefinedSearchParams(search)
      if(Object.entries(definedParams).length > 0){
        // const ageRange = parseArrayParam(search.get("ageRange", []));
        // const countries = parseArrayParam(search.get("countries", []));
        const ageRange = search["ageRange"] || filterParams['ageRange'];
        const countries = search["countries"] || filterParams['countries'];
        const growthOps = search["growthOps"] || filterParams['growthOps'];
        const taxonomies = search["taxonomies"] || filterParams['taxonomies'];
        // const tagParam = search["tagId"] || -1;
        // const tagId = tagParam === null ? -1 : Number(tagParam);
        let filterQuery = {
          ...filterParams,
          countries: countries,
          ageRange: ageRange,
          taxonomies: taxonomies,
          growthOps: growthOps
        }

        setFilters(filterQuery);
        // setTag(tagId);
      }*/
      setInitialParams();
      setInitialized();
    } else {
      // setInitialParams();
    }
  }, [loaded])

  /*
  useEffect(() => {
    console.log("filterdata", filterParams)
  }, [filterParams])
*/

  /**
   * Listen for search param updates. Apply filters when this happens.
   */
  // useEffect(() => {
  //   if(loaded){
  //     const searchParamsObj = searchParamsToObject(searchParams);
  //     const tagId = Number(searchParamsObj.tagId) || null;
  //     delete searchParamsObj.tagId
  //     console.log("search params updated", searchParamsObj);
  //      setFilters(searchParamsObj, true);
  //     setTag(tagId);
  //   }
  // }, [searchParams])

  /*
  useEffect(() => {
    
    if(loaded){
      const changed = (!_.isEqual(search, filterParams));
      if(changed){
        // const searchParamsObj = searchParamsToObject(searchParams);
        const tagId = Number(search.tagId) || null;
        delete search.tagId
        console.log("search params updated", search);
        setFilters(search, true);
        setTag(tagId);
      }
    }
  }, [search])
  */
  


  useEffect(() => {
    console.debug("navigation state change", navigation);
  }, [navigation] )
  

  return (
    <RootDiv>
      {preloaded &&
        <>
          <Container maxWidth="xl" disableGutters>
            <NavBar />
            <div style={{position: "absolute", zIndex: 10, left: 0, height:'calc(100vh - 4.5em)', width:'100%', pointerEvents: 'all'}}>
              <Background></Background> 
            </div>
            <div style={{position: "absolute", zIndex: 10, left: 0, height:'calc(100vh - 4.5em)', width:'100%', pointerEvents: 'none'}}>
              <Container maxWidth="xl" disableGutters>
                <div>
                  <Outlet />
                </div>
              </Container>
            </div>
          </Container>
          {/* <Container maxWidth="false" disableGutters sx={{position: "absolute", zIndex: 10, height:'100%'}}>
            <Background></Background> 
          </Container>
          <Container maxWidth="false" disableGutters sx={{position: "absolute", zIndex: 100, height:'100%'}}>
            <Container maxWidth="xl" disableGutters>
              <div>
                <Outlet />
              </div>
            </Container>
          </Container> */}
        </>
      }
    </RootDiv>
  );
}