import { Autocomplete, styled, TextField } from "@mui/material";

String.prototype.ngrams = function (n) {
  var r = [];

  for (var j = 1; j <= n; j++) {
    for (var i = 0; i <= this.toLowerCase().length - j; i++) {
      r.push(this.toLowerCase().substring(i, i + j));
    }
  }
  return r;
};

function intersect(array1, array2) {
  return array1.filter((value) => array2.includes(value));
}

const CustomAutocomplete = styled(Autocomplete)(({theme}) => ({
  // background: '#ff0000'
  // 'border-color': '#ffffff'
  // '&:before': {
  //     borderColor: 'white',
  // },
  // '&:after': {
  //     borderColor: 'white',
  // },
  // '&:not(.Mui-disabled):hover::before': {
  //     borderColor: 'white',
  // },
  // icon: {
  //     fill: 'white',
  // },
  // background: '#ff0000',
  color: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
    
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    }
  },
  '& .MuiInputBase-root': {
    borderRadius: '20px',
    color: 'white',
    
  },
  root: {
   
  },
}))

const customFilter = (options, state) => {
  const { inputValue } = state;
  if (!inputValue) {
    return options.slice(0, 1000);
  }
  const inputTrigrams = inputValue.ngrams(3);
  return (
    options
      // iterate over each option and compute intersect(ngram(search), all_color_ngrams)
      .map((option) => {
        const nMatches = intersect(
          inputTrigrams, // ngrams of search input (i.e. "crnflower")
          option.label.ngrams(3) // ngrams of the option (i.e. "cornflowerblue")
        ).length;
        return {
          ...option,
          nMatches,
        };
      })
      // toss out anything that had no matches
      .filter(({ nMatches }) => nMatches > 0)
      // for sanity's sake we'll only display the top 10 results. we're going to
      // order by `nMatches`. in the event of a tie the shorter word wins.
      //
      // i.e. if we're searching for "blue" then "Blue" is #1 and "Green Blue" #2
      .sort((a, b) => {
        const diff = b.nMatches - a.nMatches;
        if (diff) {
          return diff;
        }
        // if they have the same number off matching trigrams, shorter one wins
        return a.label.length - b.label.length;
      })
      // return the top 10
      .slice(0, 10)
  );
}


export default function TagSearch({options, selected, onChange}){
  return(
    <CustomAutocomplete
      autoComplete={true}
      autoSelect={true}
      disablePortal
      id="tag-autocomplete"
      options={options}
      size="small"
      sx={{ width: 200 }}
      // renderInput={(params) => <TextField {...params} label="Tag" />}
      value={selected || null}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => {
        if(value){
          return option.id === value.id;
        } else {
          return false
        }
      }}
      renderInput={params => (
        <TextField  placeholder="combo-box" {...params}  variant="outlined" />
      )}
      filterOptions={customFilter}
    />
  )
}