import { 
  useEffect, 
  createContext, 
  useContext,
  useState
} from 'react'
/*import {
    useQueryParams,
  } from 'use-query-params'*/
import {
  encodeDelimitedNumericArray,
  decodeDelimitedNumericArray,
} from 'serialize-query-params'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import _ from 'lodash';

import { searchParamsToObject } from "../utils/urlUtils";
import { useData } from "../contexts/data-context";

const UrlContext = createContext()

const CustomListParam = {
  encode: (array) => {
    if(array.length > 0){
      return encodeDelimitedNumericArray(array, ',')
    }
    return null;
  },
  decode: (arrayStr) => {
    if(arrayStr === undefined || arrayStr === null) {
      return []
    } else {
      return decodeDelimitedNumericArray(arrayStr, ',')
    }
  }
}

function UrlProvider({children}) {
  const {
    state: { preloaded, loaded, loading, filterParams },
    setFilters, setTag, setInitialFilterParams
  } = useData()
  const location = useLocation();
  const navigate = useNavigate();
  let [queryParams, setQueryParams] = useSearchParams();
  let [initialized, setInitialized] = useState(false);

/*  const [search, setSearch] = useQueryParams({
      countries: CustomListParam,
      ageRange: CustomListParam,
      growthOps: CustomListParam,
      taxonomies: CustomListParam
  })*/
  
  /**
   * On initial mount, get the search params out of the url.
   * This should happen before loading completes.
   * Set the filters and selections based on the params if valid.
   */
   const setInitialParams = () => {
    if(!initialized){
      setInitialized(true);
      const params = searchParamsToObject(queryParams);
      let setParams = false;
      for(let p in params){
        if(params[p].length > 0){
          setParams = true;
          break;
        }
      }
      // if there are params from the search query, set them.
      if(setParams){
        setInitialFilterParams(params)
        // setFilters(params)
      }
    }
  };

  useEffect(()=> {
    console.log('url provider mount');
  }, []);

  const setSearchParams = (params, replace = false) => {
    const combinedParams = {...filterParams, ...params}
    // replace empty lists with undefined
    /*let cleanParams = {}
    for (const property in params) {
        const value = params[property];
        if(Array.isArray(value) && value.length === 0){
          cleanParams[property] = undefined;
        } else {
          cleanParams[property] = value;
        }
    }*/
    // console.log("set search params", params);
    // setSearch(params, 'push');
    setQueryParams(combinedParams, {replace: replace});
    // const newLocation = updateLocation(params, location)
    // navigate(newLocation)
  }

  const goToExploreTag = (tagId) => {
    const searchParams = location.search;
    const newLocation = `/explore/${tagId}/${searchParams}`
    navigate(newLocation)
  }

  const handleSearchChange = (searchParams) => {
    const combinedParams = {...filterParams, ...searchParams}
    const changed = (!_.isEqual(combinedParams, filterParams));
    if(changed){
      console.log("search params updated", searchParams);
      setFilters(searchParams, true);
      // setTag(tagId);
    }
  }

  /**
   * Listen for changes in search params.
   * Depending on the location, we will either want to apply filter
   * or save the params for later.
   */
  useEffect(() => {
    if(loaded){
      // console.log("location", location)
      if(location.pathname.startsWith('/explore')) {
        // console.log('change filters');
        const params = searchParamsToObject(queryParams);
        handleSearchChange(params)
      } else {
        console.log('ignore')
      }
    }
  }, [queryParams]);
  // }, [search]);

  const value = {goToExploreTag, setInitialParams, setSearchParams}
  return <UrlContext.Provider value={value}>{children}</UrlContext.Provider>
}

function useURLSearchParams() {
  const context = useContext(UrlContext)
  
  if (context === undefined) {
    throw new Error('useURLSearchParams must be within a UrlProvider') 
  }
  return {...context}
}

export {UrlProvider, useURLSearchParams}