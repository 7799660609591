import AnalyticsIcon from '@mui/icons-material/Analytics';
import Fab from '@mui/material/Fab';
import { Box } from '@mui/system';
import { useState } from 'react';

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    left: 16,
};

const boxStyle = {
    position: 'absolute',
    bottom: 100,
    left: 16,
    background: 'white'
}

function TagList() {
    return (
        <div>List of Tags</div>
    )
}

function DinList() {
    return (
        <div>List of Dins</div>
    )
}


export default function DevBar() {
    const [open, setOpen] = useState(false);
    const handleClick = (event) => {
        setOpen(!open);
      };

      
    return (
        <div style={{pointerEvents: 'all'}}>
            <Fab sx={fabStyle} aria-label={"Dev Info"} color='primary' 
                onClick={handleClick}
            >
                <AnalyticsIcon />
            </Fab>
            { open &&
                <Box sx={boxStyle}>
                    <TagList />
                    <DinList />
                </Box>
            }
        </div>
    )
}