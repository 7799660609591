import { IconButton, Paper, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";
import { Stack } from "@mui/system";
import DINCard from "./DinCard";



export default function DinPanel({text}) {
  return(
    <Stack spacing={2}>
      <DINCard></DINCard>
      <DINCard></DINCard>
      <DINCard></DINCard>
      <DINCard></DINCard>
    </Stack>
  )
}