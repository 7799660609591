import { useReducer, useEffect, useState } from "react";
import { Autocomplete, FormControlLabel, FormGroup, Paper, TextField } from "@mui/material"
import { styled } from "@mui/system"
import { useData, initialFilterForm, AGE_RANGE_OPTIONS } from "../contexts/data-context";
import { useURLSearchParams } from "../contexts/url-context";

import Title from "./Title";
// import { IOSSwitch } from "./inputs/Switch";
import { Switch } from "@mui/material";
// import { useSearchParams } from "react-router-dom";
import { searchParamsToObject } from "../utils/urlUtils";
// import {
//   useQueryParams,
//   StringParam,
//   ArrayParam
// } from 'use-query-params'

const GrowthOppsMenuContainer = styled('div')`
  background-color: black;
  flexGrow: 1;
`

const GrowthOppsBar = styled('div')`
  padding: 0.5em;
  justify-self: end;
  flex-grow: 0;
`

export default function GrowthOppsMenu() {
  const {
    state: {categories, initialized, loaded, initialFilterParams},
  } = useData()
  /*let initialState = {}
  categories.growth_opportunities.forEach((element, i) => {
    if(i in initialFilterParams.growthOps){
      initialState[i] = true
    } else {
      initialState[i] = false
    }
  });*/

  // const [initFilterState, setInitFilterState] = useState(initialState)
  const [filterState, setFilterState] = useState({})
  const {search, setSearchParams} = useURLSearchParams();

  
  // Initial mount
  useEffect(() => {
    if(initialized){
      let initialState = {}
      categories.growth_opportunities.forEach((element, i) => {
        const selectedGrowthOps = Object.values(initialFilterParams.growthOps)
        if(selectedGrowthOps.indexOf(i) > -1){
          initialState[i] = true
        } else {
          initialState[i] = false
        }
      });
      setFilterState(initialState)
    }
  }, [initialFilterParams, initialized])


  // listen for changes in the filter (by clicking only) // START HERE
  useEffect(() => {
    if(loaded){
      // console.log("filterState", filterState);
      let growthOpsArray = [];
      for(const prop in filterState){
        if(filterState[prop] === true){
          growthOpsArray.push(prop);
        }
      }

      // empty array if all are selected
      /*if(growthOpsArray.length === Object.keys(filterState).length){
        growthOpsArray = []
      }*/
      // let newParams = searchParamsToObject(searchParams);
      // newParams = {...newParam s, growthOpps: growOpsArray};
      // setSearchParams(newParams);
      // console.log(growOpsArray);

      // setSearch({growthOpps: growOpsArray}, 'pushIn');
      setSearchParams({growthOps: growthOpsArray})
    }
  }, [filterState])

  const handleSwitchChange = (event) => {
    // console.log(event.target.checked)
    const key = Number(event.target.value);
    const value = event.target.checked;
    // set the state of the filters
    setFilterState(
      { ...filterState, 
        [key]: value
      }
    )
  }
  const filterOrder = [
    2, 5, 1, 3, 4, 6, 0
  ]

  return (
    <GrowthOppsMenuContainer>
      { initialized &&
        <GrowthOppsBar elevation={1}>
          <Title>Growth Opportunities</Title>
          {categories.growth_opportunities && Object.values(filterState).length &&
            <FormGroup>
              {filterOrder.map((item, i) =>
                <FormControlLabel key={item} control={<Switch sx={{ m: 1 }} defaultChecked={filterState[item]} value={item} onChange={handleSwitchChange} />}label={categories.growth_opportunities[item]}/>
              )}
            </FormGroup>
          }
        </GrowthOppsBar>
      }
    </GrowthOppsMenuContainer>
  )
}