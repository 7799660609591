import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useData } from "../../contexts/data-context";
import { makeUrlHash } from "../../utils/urlUtils";

const DgOverlayContainer = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const CountersContainer = styled('div')`
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
`

const Counters = () => {
  const {
    state: {selected, tags}
  } = useData()

  useEffect(() =>{
    console.log("TAGS", tags)
  }, [tags])

  // get din and tag counts 
  return(
    <CountersContainer>
      <div>{tags.length} TAGS</div>
      { selected && selected.length &&
        <div>{selected.length} DINS</div>
      }
    </CountersContainer>
  )
}

export default function DgOverlay() {
  let location = useLocation();
  const {
    state: {selectedTag, filterParams}
  } = useData()
  const [buttonLink, setButtonLink] = useState('/report-card/');
  
  // const updateUrl = () => {
  //   setSearchParams();
  // }

  useEffect(() => {
    if(selectedTag){
      // const hash = makeUrlHash(selectedTag.id, filterParams)
      // TODO: set the id to something that includes the country, age group, and tag id
      // setButtonLink(`/report-card/${hash}`)
      // const params = {...filterParams, tagId: selectedTag.id};
      // setSearchParams(params);
      // const searchParams = location.search
      setButtonLink(`/report-card/${location.search}`);
    }
  }, [selectedTag, filterParams])
  
  return (
    <DgOverlayContainer>
      <>
      { (location.pathname === '/explore' || location.pathname === '/explore/') && selectedTag &&
        <>
        <Button component={Link} variant="contained" to={buttonLink} sx={{
            display: 'block',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }}><strong>{selectedTag.label}</strong> Report Card</Button>
        </>
      }
      { location.pathname.startsWith('/explore') && 
        <>
          <Counters />
        </>
      }
      </>
    </DgOverlayContainer>
  )
}