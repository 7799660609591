import SideBar from "../components/SideBar";
import { useData } from "../contexts/data-context";
import GrowthOppsMenu from "../components/GrowthOppsMenu";
import DevBar from "../components/DevBar";

export default function Explore() {
  const {
    state: {loaded}
  } = useData()

  
  return (
    <main style={{ padding: "1rem 0"}}>
      <SideBar>
        <GrowthOppsMenu />
      </SideBar>
      <DevBar />
    </main>
  );
}