import { Chip, Container, Divider, Grid, IconButton, List, ListItem, Paper} from "@mui/material"
import { Link, redirect, useLocation, useNavigation, useSearchParams } from "react-router-dom";
import Header from "../components/report-card/Header";
import Observations from "../components/report-card/Observations";
import MoreDetails from "../components/report-card/MoreDetails";
import DinPanel from "../components/report-card/DinPanel";
import RelatedTags from "../components/report-card/RelatedTags";
import React, { useEffect, useState } from "react";
import { useData } from "../contexts/data-context";
import { parseArrayParam } from "../helpers/search-queries";
import { Stack, styled } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import Highlight from "../components/report-card/Highlight";
import StatTable from "../components/report-card/StatTable";
import TimeOfDayChart from "../components/report-card/TimeOfDayChart";
import DayOfWeekChart from "../components/report-card/DayOfWeekChart";


export function reportCardLoader({request}){
  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const tagId = searchParams.get("tagId") || null;
  console.log(request, url);
  if(tagId == null){
    return redirect('/')
  }
}

const HeaderContainer = styled('div')`
  display: flex;
`

function DinDataLayout({data, userScrolled}) {
  return (
    <Grid container>
      {/* left side */}
      <Grid item xs={3}>
        <h3>Showing 37 DINs of 58</h3>
        <div>Filter by a tag</div>
        <div>GREENFIELD</div>
          <List>
            <ListItem><Chip label="Restaurant" /></ListItem>
          </List>
        <div>LOW-HANGING FRUIT</div>
          <List>
            <ListItem><Chip label="Delivery" /></ListItem>
          </List>
        <div>STRENGTH</div>
          <List>
            <ListItem><Chip label="Food and Drink" /></ListItem>
            <ListItem><Chip label="Information" /></ListItem>
            <ListItem><Chip label="Likely Shopping Intent" /></ListItem>
          </List>
      </Grid>

      {/* right side */}
      <Grid item xs={9} justifyContent="flex-end">
        Easist to Hardest | Google | Other | Download
        <DinPanel />
      </Grid>

      
      {/* <Observations />
      <hr />
      <RelatedTags />
      <hr />
      <MoreDetails /> */}
    </Grid>
  )
}

function StatsColumn({}) {
  return(
    <Stack spacing={2}>
      <section>
        <h4>About</h4>
        <div>Defined by Knowledge Graph. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
      </section>
      <Divider />
      <section>
        <h4>Prevalence</h4>
        <StatTable title="TOP COUNTRIES" />
        <StatTable title="TOP GENERATIONS" />
      </section>
      <Divider />
      <section>
        <h4>Performance</h4>
        <StatTable title="TOP METHODS" />
        <StatTable title="EASIEST METHODS" />
      </section>
      <Divider />
      <section>
        <h4>Context</h4>
        <StatTable title="TOP FORMATS" />
        <StatTable title="TOP DEVICES" />
        <StatTable title="TOP LOCATIONS" />
        <TimeOfDayChart title="TIME OF DAY" />
        <DayOfWeekChart title="DAY OF WEEK" />
      </section>
    </Stack>
  )
}

export default function ReportCard() {
  const {
    state: { selectedTag },
    getTagData
  } = useData()
  const navigation = useNavigation()
  // let [searchParams, setSearchParams] = useSearchParams();
  const [userScrolled, setUserScrolled] = useState(false);

  const loadTagData = () => {
    // const ageRange = parseArrayParam(searchParams.get("ageRange"));
    // const countries = parseArrayParam(searchParams.get("countries"));
    // const tagId = selectedTag
    // console.log("mount report card", ageRange, countries, selectedTag);
  }
  
  useEffect( () => {
    console.log("report card mount", selectedTag);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [] )

  const onScroll = (e) => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 100;

    if (distanceY > shrinkOn) {
      setUserScrolled(true)
    } else {
      setUserScrolled(false)
    }
  }

  // const { tagData } = useLoaderData();
  useEffect(() => {
    // get the tagId

    // load tag data
    loadTagData();
  }, [selectedTag])

  const location = useLocation();
  const exploreLink = `/explore/${location.search}`;
  
  return (
    <>
    { selectedTag &&
      <Container key="ReportCardContainer" maxWidth="false" disableGutters style={{pointerEvents: 'all', height: 'auto'}}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            minHeight: '100vh'
          }}
        >
          <Grid container spacing={3}>

            {/* Left side */}
            <Grid item xs={9}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header text={selectedTag.label} small={userScrolled} />
                </Grid>

                <Grid item xs={12}>
                  <Highlight />
                </Grid>

                <Grid item xs={12}>
                  <Divider variant="middle" />
                </Grid>

                <Grid item xs={12}>
                  <DinDataLayout data={selectedTag} userScrolled={userScrolled} />
                </Grid>
              </Grid>
            </Grid>

            {/* Right side */}
            <Grid item xs={3} justifyContent="flex-end">

              <Grid container spacing={3}>
                <Grid item container xs={12}  direction="row" justifyContent="flex-end">
                  <IconButton component={Link} to={exploreLink} aria-label="close">
                    <CloseIcon />
                  </IconButton>

                </Grid>
                <Grid item xs={12}>
                  <StatsColumn />
                </Grid>
              </Grid>
              
              
            </Grid>
          </Grid>
        </Paper>
      </Container>
    }
    </>
  );
}