import { AppBar, Box, Grid } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { styled } from '@mui/system';
import DgFilterBar from './DgFilterBar';

const NavBarContainer = styled(Grid)`
  position: relative;
  background: black;
  color: white;
  height: calc(90px);
  width: 100%;
  z-index: 100;
  pointer-events: all;
`

const Logo = styled('div')`
  color: white;
  font-size: 20px;

  a:link {
    text-decoration: None;
  }
  a:hover {
    text-decoration: underline;
  }
  a:visited {
    color: white;
  }
`

export default function NavBar() {
  let location = useLocation();

  return (
    <NavBarContainer
      container
      direction="row"
      justifyContent="center"
      alignItems="center">
      <Grid item xs={6} >
        <Logo>
          <Link to="/">Google DIN Tool</Link>
        </Logo>
      </Grid>
      { location.pathname !== '/' &&
        <Grid item xs={6}>
          <DgFilterBar />
        </Grid>
      }
    </NavBarContainer>
  )
}


 /*
        // <Box>
            

             <Grid container spacing={2} direction="row"
  justifyContent="flex-end"
  alignItems="center">
              <Grid item>
                <div>thing 1</div>  
              </Grid>
              <Grid item>
                <div>thing 2</div>  
              </Grid>
              <Grid item>
                <div>thing 2</div>  
              </Grid>
            </Grid> 
        // </Box>*/