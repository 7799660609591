import './Background.scss'
import createGlobe from "cobe";
import { useEffect, useRef } from "react";
import { useCalculate } from '../../contexts/data-context';
import DgOverlay from './DgOverlay';
import { Container, styled } from '@mui/system';

const BackgroundContainer = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;

  canvas {
    position: absolute;
    left: 50%;
    top: calc(50% + 2.5em);
    transform: translate(-50%, -50%);
    z-index: 0;
  }
`

const Canvas = styled('canvas')`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`

const Background = () => {
  const canvasRef = useRef();
  // const {state} = useCalculate()

  useEffect(() => {
    let phi = 0;

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 600 * 2,
      height: 600 * 2,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.1, 0.8, 1],
      glowColor: [1, 1, 1],
      markers: [
        // longitude latitude
        { location: [37.7595, -122.4367], size: 0.03 },
        { location: [40.7128, -74.006], size: 0.1 }
      ],
      onRender: (state) => {
        // var ctx = canvasRef.getContext("2d");
        // ctx.fillStyle = "blue";
        // ctx.fillRect(0, 0, canvasRef.width, canvasRef.height);

        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi;
        phi += 0.01;
      }
    });

    return () => {
      globe.destroy();
    };
  }, []);

  return (
    <>
    <Container maxWidth="xl" sx={{background: '#2B2A30', height:'100%', borderRadius: '20px', pointerEvents: 'all'}}>
      <DgOverlay />
      <Canvas
        ref={canvasRef}
        style={{ width: 600, height: 600, maxWidth: "100%", maxHeight: "100%", aspectRatio: 1}}
      />
    </Container>
    </>
  )
}

export default Background