import * as React from 'react'
import { useEffect, useCallback } from 'react'
import { defer } from 'react-router-dom';
import usePrevious from '../utils/usePrevious';
import _ from 'lodash';

// Import your worker 
import worker from 'workerize-loader!../workers/din-worker.js'; // eslint-disable-line import/no-webpack-loader-syntax

const dataRoot = process.env.REACT_APP_DATA_ENV === 'prod' ? '/_data' : '/_data_fake';
const DataContext = React.createContext()
// const worker = new window.Worker("/workers/din-worker.js");


// Create an instance of your worker
const workerInstance = worker()


console.log("DATA ENVIRONMENT", process.env.REACT_APP_DATA_ENV)
  

const initialFilterForm = {
  countries: [],
  ageRange: [],
  taxonomies: [],
  growthOps: [0, 1, 2, 3, 4, 5, 6],
};

const AGE_RANGE_OPTIONS = [
  {
    key: 'GloYo',
    value: [13, 24]
  },
  {
    key: 'All Ages',
    value: [25, 100]
  }
]



// load this data
const dataSources = [
  {
    id: 'categories',
    location:  `${dataRoot}/categories.json`,
    type: 'json'
  },
  {
    id: 'countries',
    location: `${dataRoot}/countries.bin`,
    type: 'bin'
  },
  {
    id: 'ages',
    location: `${dataRoot}/ages.bin`,
    type: 'bin'
  },
  {
    id: 'genders',
    location: `${dataRoot}/genders.bin`,
    type: 'bin'
  },
  {
    id: 'difficulties',
    location: `${dataRoot}/difficulties.bin`,
    type: 'bin'
  },
  {
    id: 'use_googles',
    location: `${dataRoot}/use-googles.bin`,
    type: 'bin'
  },
  {
    id: 'devices',
    location: `${dataRoot}/devices.bin`,
    type: 'bin'
  },
  {
    id: 'din_tags',
    location: `${dataRoot}/din-tags.bin`,
    type: 'bin'
  },
  {
    id: 'tag_strings',
    location: `${dataRoot}/tag-strings.bin`,
    type: 'bin'
  },
  {
    id: 'tag_names',
    location: `${dataRoot}/tag-names.bin`,
    type: 'bin'
  },
  {
    id: 'tag_taxonomies',
    location: `${dataRoot}/tag-taxonomies.bin`,
    type: 'bin'
  },
  {
    id: 'tag_growth_opps',
    location: `${dataRoot}/tag-growth-opps.bin`,
    type: 'bin'
  }
]

const dinTextSources = [
  {
    id: 'din_text',
    location: `${dataRoot}/din-text.txt`,
    type: 'txt'
  }
]

const initialState = {
  loading: false,
  preloaded: false,
  loaded: false,
  dinData: {},
  selected: undefined,
  allTagsList: [],
  tags: {},
  selectedCount: undefined,
  loadingExtraData: false,
  loadedExtraData: false,
  dinText: [],
  initialFilterParams: {...initialFilterForm},
  filterParams: {},
  filtering: false,
  selectedTag: null,
  selectedTagId: undefined,
  initialized: false
}


function dataReducer(state, action) {
  let dinData = {...state.dinData}
  switch (action.type) {
    case 'START_PRELOAD_DATA': {
      return {
        ...state,
        loading: true
      }
    }
    case 'END_PRELOAD_DATA': {
      return {
        ...state,
        loading: false,
        preloaded: true
      }
    }
    case 'SET_CATEGORIES': {
      return { 
        ...state,
        categories: action.payload
      }
    }
    case 'SET_DIN_DATA': {
      dinData[action.payload.name] = new Int8Array(action.payload.data);
      return {
        ...state,
        dinData: dinData
      }
    }
    
    case 'START_LOAD_DIN_TEXT': {
      return {
        ...state,
        loadingExtraData: true
      }
    }
    case 'END_LOAD_DIN_TEXT': {
      return {
        ...state,
        loadingExtraData: false,
        loadedExtraData: true
      }
    }
    case 'SET_DIN_TEXT': {
      return {
        ...state,
        dinText: action.payload
      }
    }

    case 'SET_FILTERED': {
      let selected = action.payload.selected;
      let tags = action.payload.tags;
      return {
        ...state,
        selected: selected,
        selectedCount: selected.length,
        tags: tags,
        filtering: false
      }
    }

    case 'SET_WORKER_DATA_LOADED': {
      return {
        ...state,
        loaded: true,
        allTagsList: action.payload.allTagsList
      }
    }
    case 'SET_INITIAL_FILTER_PARAMS': {
      return {
        ...state,
        initialFilterParams: action.payload,
      }
    }
    case 'SET_FILTER_PARAMS': {
      return {
        ...state,
        filterParams: action.payload,
        filtering: true
      }
    }

    case 'SELECT_TAG_ID': {
      return {
        ...state,
        selectedTagId: action.payload.tagId
      }
    }

    case 'SELECT_TAG': {
      return {
        ...state,
        selectedTag: action.payload.tagData
      }
    }

    case 'SET_INITIALIZED': {
      return {
        ...state,
        initialized: true
      }
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const parseCsv = (data) => {
  console.log('parseCsv');
  // load data from csv
  const delimiter = ','
  const titles = data.slice(0, data.indexOf('\n')).split(delimiter);

  // This variable will store the valuesfrom the data
  const titleValues = data.slice(data
    .indexOf('\n') + 1).split('\n');

  // Map function will iterate over all values of title values array and
  // append each object at the end of the array
  const ansArray = titleValues.map(function (v) {
    const values = v.split(delimiter);
    const storeKeyValue = titles.reduce(
      function (obj, title, index) {
        obj[title] = values[index];
        return obj;
    }, {});
    return storeKeyValue;
  });
  return ansArray;
}

//  TODO: Move the file readers into a util script
function readTextFile(file)
{
  const promise = new Promise (function (resolve, reject) {
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", file, false);
    rawFile.onreadystatechange = function ()
    {
        if(rawFile.readyState === 4)
        {
            if(rawFile.status === 200 || rawFile.status == 0)
            {
                resolve(rawFile.responseText);
            }
        }
    }
    rawFile.send(null);
  });
  return promise;
}

function readBinFile(file)
{
  const promise = new Promise (function (resolve, reject) {
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", file);
    rawFile.responseType = "arraybuffer";
    rawFile.onreadystatechange = function ()
    {
        if(rawFile.readyState === 4)
        {
            if(rawFile.status === 200 || rawFile.status == 0)
            {
                var content = rawFile.response;
                resolve(content);
            }
        }
    }
    rawFile.send(null);
  });
  return promise;
}

function readFile(fileData)
{
  if(fileData.type === 'bin') {
    return readBinFile(fileData.location)
  } else {
    return readTextFile(fileData.location)
  }
}

async function cacheData(key, value){
  const cache = await caches.open('din-cache');
  const request = new Request(key);
  request.responseType = 'arraybuffer'
  cache.put(request, new Response(value, {headers: {
    'Content-Type': 'arraybuffer'
  }}));
}

function splitDinText(textData) {
  var data = [];
  var lines = textData.split('\n');
  for(var line = 0; line < lines.length; line++){
    data.push(lines[line]);
  }
  return data
}


function DataProvider({children}) {
  const [state, dispatch] = React.useReducer(dataReducer, {...initialState});
  const prevState = usePrevious(state);

  // Preload from files
  useEffect(() => {
    console.log('INIT provider');
    loadFileData().then((data) => {
      // set filter params for 
      dispatch({type: 'END_PRELOAD_DATA'});
      console.log("data preloaded", data);    
    })
    return () => {
      console.log('terminate provider');
    };
  }, []);

  useEffect(() => {
    if(!state.loading && state.preloaded) {
      loadWorkerData().then((data) => {
        dispatch({type: 'SET_WORKER_DATA_LOADED', payload: {allTagsList: data.allTagsList}})
        loadDinText();
      });
    }
  }, [state.dinData, state.preloaded]);

  /**
   * Listen for when categories is loaded.
   */
  useEffect(() => {
    if(state.categories !== undefined) {
      /*let initialGrowthOpps = []
      state.categories.growth_opportunities.forEach((element, i) => {
        initialGrowthOpps.push(i)
      });
      setFilters({...state.filterParams, growthOps: initialGrowthOpps});
    */
      // set filters when categories is loaded
      setFilters(state.initialFilterParams)
    }
  }, [state.categories])

  

  const loadFileData = () => {

    const promise = new Promise (function (resolve, reject) {
      dispatch({type: 'START_PRELOAD_DATA'})
      let count = 0, total = dataSources.length;
      for (let i=0; i < total; i++) {
        let dataSource = dataSources[i];
        readFile(dataSource).then((data) => {
          if(dataSource.id === 'categories'){
            const categoryData = JSON.parse(data)
            dispatch({type: 'SET_CATEGORIES', payload: categoryData})
          } else {
            if(dataSource.type === 'bin') {
              const id = dataSource.id;
              dispatch({type: 'SET_DIN_DATA', payload: {name:id, data: data}})
            }
          }
          count++;
          if(count >= total) {
            resolve();
          }
        })
      }
    });
    return promise;
    // setTimeout(function () {
    //   dispatch({type: 'END_PRELOAD_DATA'});

    //   // start loading other data
    //   loadDinText()
    // }, 5000); // Execute something() 1 second later.
    
    
    // start loading other data
    
    // loadDinText()
  }

  const loadDinText = async () => {
    dispatch({type: 'START_LOAD_DIN_TEXT'})
    for (var dataSource of dinTextSources) {
      await readFile(dataSource).then((data) => {
        if(dataSource.id === 'din_text'){
          const dinText = splitDinText(data)
          dispatch({type: 'SET_DIN_TEXT', payload: dinText})
        }
      })
    }
    dispatch({type: 'END_LOAD_DIN_TEXT'});
  }

  const loadWorkerData = () => {
    const promise = new Promise (function (resolve, reject) {
      

      // SET_DIN_DATA
      // worker.postMessage({message:'LOAD', data: state.dinData});
      // worker.onerror = (err) => err;
      // worker.onmessage = (e => {
      //   dispatch({type: 'SET_WORKER_DATA_LOADED', payload: {allTagsList: e.data.allTagsList}})
      //   resolve();
      // });

      workerInstance.callLoad(state.dinData);
      workerInstance.addEventListener('message', (e) => {
        // dispatch({type: 'SET_WORKER_DATA_LOADED', payload: {allTagsList: e.data.allTagsList}})
        resolve(e.data);
      })
    });
    return promise
  };

  const filter = (data) => {
    // transform ageRange
    let filterData = {...initialFilterForm, ...data}

    if(!_.isEqual(state.filterParams, filterData)){
      console.log("filter dins now", filterData);
      dispatch({type: 'SET_FILTER_PARAMS', payload: filterData})
      // TODO: set selected filter params here and then listen for that state
      // change before starting the filter
      /*worker.postMessage({message:'FILTER', data: filterData});
      worker.onerror = (err) => err;
      worker.onmessage = (e) => {
        console.log('worker done');
        dispatch({type: 'SET_FILTERED', payload: e.data})
      };*/
      let filterPromise = new Promise((resolve, reject) => {
        // remove anything that's undefined
        // let cleanData = {}
        // for (const property in data) {
        //   if(data[property] != undefined){
        //     cleanData[property] = data[property];
        //   }
        // }
        if(data.ageRange?.length){
          filterData.ageRange = data.ageRange.map((v) => AGE_RANGE_OPTIONS[v].value);
        }
        workerInstance.callFilter(filterData);
        workerInstance.addEventListener('error', (e) => {
          reject(e.data)
        })
        workerInstance.addEventListener('message', (e) => {
          resolve(e.data)
        })
      })
      filterPromise.then((data)=>{
        console.log('worker done');
        dispatch({type: 'SET_FILTERED', payload: data})
      })
      
    } else {
      console.log('filter params are the same');
    }
  }


  /**
   * Set the initial filter params, likely coming from url query params
   * @param {*} params 
   */
  const setInitialFilterParams = (params) => {
    dispatch({type: 'SET_INITIAL_FILTER_PARAMS', payload: params})
  }


  /**
   * Set the filters parameters WITHOUT actually running the filter
   * @param {Object} data 
   */
  const setFilters = useCallback((data, apply = false) => {
    if(apply) {
      filter(data);
    } else {
      dispatch({type: 'SET_FILTER_PARAMS', payload: data})
    }
  })

  // const setTagId = (tagId) => {
  //   dispatch({type: 'SELECT_TAG_ID', payload: {tagId: tagId}})
  // }

  const setTag = (tagId) => {
    // let tagData = getTagData(tagId) || {id: '', label: ''};
    let tagData = getTagData(tagId) || null;
    dispatch({type: 'SELECT_TAG', payload: { tagData }})
  }

  const setInitialized = () => {
    dispatch({type: 'SET_INITIALIZED'});
  }

  const getTagData = (tagId) => {
    if(tagId !== null && tagId !== undefined && tagId > -1){
      // Return data for report card
      const tag = state.allTagsList.find(element => element.id === tagId);
      return tag;
    } else {
      return {
        id: undefined,
        label: undefined
      };
    }
  }

  /*// Route Loaders
  async function rootLoader({params}){
    console.log('run rootLoader', params, state.preloaded);
    if( state.preloaded ){
      // 
      const promise = new Promise (function (resolve, reject) {
        const ageRange = parseArrayParam(searchParams.get("ageRange"));
        const countries = parseArrayParam(searchParams.get("countries"));
        const tagId = searchParams.get("tagId");
        let filterQuery = {
          ...initialFilterForm,
          countries: countries,
          ageRange: ageRange
        }
        // set the 
        dispatch({type: 'SET_FILTER_PARAMS', payload: filterQuery})
        dispatch({type: 'SET_TAG', payload: {tag: tagId}})
        resolve();
      });
      return defer({promise});
    }
    return undefined;
  }*/

  async function tagLoader({params}){
    console.log('run tagLoader', params, state.preloaded);
    if( params.tagId && state.preloaded && state.allTagsList.length ){
      const tagData = new Promise (function (resolve, reject) {
        resolve(getTagData(params.tagId))
      });
      return defer({tagData});
    }
    return {
      tagData: null
    };
  }
  
  const value = {state, dispatch, setFilters, filter, setTag, setInitialized, tagLoader, setInitialFilterParams}
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}


function useData() {

  const context = React.useContext(DataContext)
  const { 
    state,
    dispatch
  } = context;
  

  // const prevState = usePrevious(state);

  // useEffect(() => {
  //   console.log('INIT')
  //   return () => {
  //     console.log('terminate worker');
  //     // worker.terminate();
  //   };
  // }, []);

  

  if (context === undefined) {
    throw new Error('useData must be within a DataProvider') 
  }

  const getDinText = (start, count) => {
    let textArray = [];
    const last = Math.min(start+count, state.selectedCount)
    for(var i=start; i < last; i++) {
      textArray.push(state.dinText[state.selected[i]])
    }
    return textArray;
  }
  
  return {...context, getDinText}
}

export {DataProvider, useData, initialFilterForm, AGE_RANGE_OPTIONS}