import { Grid } from "@mui/material";

export default function Highlight() {
  return(
    <>
      <h2>This is a <em>semiweekly</em> need for <em>many people</em></h2>
      <Grid container>
        <Grid item xs={6}>People are more likely to use Google</Grid>
        <Grid item xs={6}>Easier to solve on Google than on other products</Grid>
      </Grid>
    </>
  )
}