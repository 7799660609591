import { Paper } from "@mui/material";
import { styled } from "@mui/system";
import Title from "../Title";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const DinItem = ({timeText, text}) => {
  return (
    <Item>
      <Title>{timeText}</Title>
      <div>{text}</div>
    </Item>
  )
}

const ParticipantTable = ({data}) => {
  return (
    <>
      
    </>
  )
}

export default function DINCard() {
  const dinData = {
    time: "10:48 AM",
    date: "MAR 21 2021",
    what: "What food delivery services had discounts available?",
    why: "I want to know this information to possibly save money.",
    participant: {
      gender: "Male",
      age: "24",
      country: "Brazil",
      importance: "Somewhat important",
      difficulty: "Extremely easy",
      where: "At home.",
      device: "phone",
      why: "Because it's the fastest/easiest way",
      results: "Found all of it"
    }
  }
  return(
    <Item>
      <Title>{dinData.time}, {dinData.date}</Title>
      <h3>{dinData.what}</h3>
      <h4>{dinData.why}</h4>
      <ParticipantTable data={dinData.participant} />
        
    </Item>
  )
}