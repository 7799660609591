import { Stack, ListItem } from '@mui/material'
import { styled } from '@mui/system'
import Title from '../Title'

const ChartEl = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-around;
  align-items: flex-end;
`

const TimeBarEl = styled('div')`
  width: 10px;
  height: ${props => props.value * 100.0}%;
  background-color: #9FB5FD;
  border-radius: 10px;
  
`

function TimeBar({value}) {
  return (
    <TimeBarEl value={value} />
  )
}


export default function TimeOfDayChart({title}) {

  const values = [
    0.0, 0.0, 0.1, 0.2, 0.7, 0.75,
    0.5, 0.6, 0.5, 0.1, 1.0, 0.9,
    0.7, 0.4, 0.7, 0.7, 0.1, 0.4,
    0.3, 0.1, 0.1, 0.2, 0.0, 0.1
  ]

  return (
    <>
      <Title>{title}</Title>
      <ChartEl>
        {values.map((x, i) => <TimeBar key={i} value={x}></TimeBar>)}
      </ChartEl>
    </>
  )
}