import { useReducer, useEffect } from "react";
import { Button, FormControl, Grid } from '@mui/material';
import { useData, initialFilterForm, AGE_RANGE_OPTIONS } from "../contexts/data-context";
import MultipleSelectChip from "./forms/MultiSelectChip";
// import MultiSelect from "./forms/MultiSelect"


const formReducer = (state, action) => {
  switch (action.type) {
    case 'HANDLE MULTISELECT CHANGE': {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    case 'SET_FILTERS': {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state;
  }
}

export default function FilterForm() {
  const {
    state: {categories, selectedCount, filterParams},
    filter
  } = useData()
  const [state, dispatch] = useReducer(formReducer, {...initialFilterForm});

  const handleMultiselectChange = (e) => {
    // const selectedValues = [].slice.call(e.target.value).map(item => Number(item.value))
    const selectedValues = e.target.value;
    console.log(e.target.name, selectedValues)
    dispatch({
      type: "HANDLE MULTISELECT CHANGE",
      field: e.target.name,
      payload: selectedValues
    })
  }
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    
    let formData = {...state}
    // if(formData.ageRange?.length){
      // formData.ageRange = formData.ageRange.map((v) => AGE_RANGE_OPTIONS[v].value);
    // }
    console.log(formData)
    // filter(formData);
  };

  useEffect(() => {
    dispatch({type: 'SET_FILTERS', payload: filterParams})
  }, [filterParams])
  

  return (
    <>
      { categories &&
  
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MultipleSelectChip label={'Countries'} name='countries' options={categories.country} field={state.countries} onChange={handleMultiselectChange} />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectChip label={'Gender'} name='genders' options={categories.gender} field={state.genders} onChange={handleMultiselectChange} />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectChip label={'Device'} name='devices' options={categories.device} field={state.devices} onChange={handleMultiselectChange} />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelectChip label={'Age Range'} name='ageRange' options={AGE_RANGE_OPTIONS.map(r => r.key )} field={state.ageRange} onChange={handleMultiselectChange} />
            <Button variant="primary" type="submit">
              Filter
            </Button>
            {selectedCount && 
              <div>filtered: {selectedCount}</div>
            }
          </Grid>
        </Grid>
      </form>
      }
    </>
  )
}