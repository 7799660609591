
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from "react-router-dom";
import { createTheme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { QueryParamProvider
 } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import HomeCard from './components/HomeCard';
import FilterCard from './components/FilterCard';
import Explore from './routes/explore';
import Root, {rootLoader} from './routes/root';
import ReportCard, { reportCardLoader } from './routes/reportCard';
import { useData } from "./contexts/data-context"
import ErrorPage from "./pages/error-page";
import { UrlProvider } from './contexts/url-context';
import { parse, stringify } from 'query-string';

import './App.css';

const router = createBrowserRouter(
  
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <UrlProvider>
          <Root />
        </UrlProvider>
      }
      errorElement={<ErrorPage />}
      loader={rootLoader}
    >
      <Route
        index={true}
        element={<HomeCard />}
      ></Route>
      <Route
        path="/filter/"
        element={<FilterCard />}
      ></Route>
      <Route
        path="/explore/"
        element={<Explore />}
      >
       <Route
        path="/explore/:tagId/"
        element={<Explore />}
        ></Route> 
      </Route>
      <Route
        path="/report-card/:tagId/"
        loader={reportCardLoader}
        element={<ReportCard />}
      ></Route>
  </Route>
));


function App() {
  const {
    state: {loading, loaded},
    tagLoader
  } = useData()

  const theme = createTheme({
  });

  useEffect(() => {
  }, [])
  
  


  return (
    <div className="App">
        <ThemeProvider theme={theme}>
          { loading ? (
            <CircularProgress />
          ) : (
            <RouterProvider router={router} />
          )}
        </ThemeProvider>
      
    </div>
  );
}

export default App;
