import { useState, useEffect } from "react"
import { Button, Paper, Stack } from '@mui/material';

import { Link, Outlet } from "react-router-dom";

import { useData } from "../contexts/data-context"

import './HomeCard.scss'
import styled from "@emotion/styled";

const HomeCardContainer = styled(Paper)({
  width: '45%',
  height: '100%',
  padding: '100px 1.5em 1.5em',
  boxShadow: '4px 0px 8px 0px rgb(0 0 0 / 20%)',
  top: '0px',
  pointerEvents: 'all'
})

const HomeCard = () => {
  const {
    state: {selectedCount},
    getDinText
  } = useData()

  return(
    <HomeCardContainer elevation={3} square={true}>
      <h1>Welcome</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a nisl quis magna vehicula semper sed eu felis. Vestibulum massa leo, sollicitudin ut elementum iaculis, vehicula id mauris. Duis ut volutpat erat. Donec varius ornare ante, nec mollis nunc lacinia ut. Aliquam gravida purus non scelerisque interdum. Curabitur bibendum tristique condimentum. Pellentesque imperdiet hendrerit magna, sed posuere ligula venenatis ut. Aenean non tincidunt ante. Vestibulum mollis, justo commodo fringilla volutpat, turpis libero pulvinar metus, in dignissim nulla arcu a ligula. Suspendisse a tortor luctus, rutrum sem quis, scelerisque leo. Duis dolor lorem, malesuada in convallis ac, rhoncus vel sapien. Phasellus euismod urna a risus eleifend tempor. Vestibulum iaculis nunc vitae pretium suscipit. Donec et ligula augue. Nulla facilisi. Phasellus eu bibendum diam.</p>
      <Stack spacing={2} direction="column" justifyContent="center" alignItems="flex-start">
        <Button component={Link} to="/explore"  variant="contained">Get Started</Button>
        <Button component={Link} to="/filter"  variant="outlined">Filters</Button>
      </Stack>
    </HomeCardContainer>
  )
}

export default HomeCard