import { useReducer, useEffect, useState } from "react";
import { Autocomplete, Grid, Paper, TextField } from "@mui/material"
import { styled } from "@mui/system"
import { useData, initialFilterForm, AGE_RANGE_OPTIONS } from "../contexts/data-context";
import MultipleSelectChip from "./forms/MultiSelectChip";
import SingleSelect from "./forms/SingleSelect";
import TagSearch from "./inputs/TagSearch";
import { useURLSearchParams } from "../contexts/url-context";
import { useNavigate } from "react-router-dom";

const FILTER_FIELDS = ['countries', 'ageRange', 'taxonomies']

const formReducer = (state, action) => {
  switch (action.type) {
    case 'HANDLE SELECT CHANGE': {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    case 'SET_FILTERS': {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state;
  }
}


export default function DgFilterBar() {
  const {
    state: {categories, selectedCount, filterParams, tags, filtering, allTagsList, selectedTag, preloaded, loaded, initialized},
    filter, setTag
  } = useData()
  
  const [filterState, dispatch] = useReducer(formReducer, {});
  const [results, setResults] = useState([])
  // const [searchParams, setSearchParams] = useSearchParams();
  const {goToExploreTag, setSearchParams} = useURLSearchParams();
  // const navigate = useNavigate();

  useEffect(() => {
    console.log("initialized", initialized);
    if (initialized) {
      let initialParams = {}
      for(let field_idx in FILTER_FIELDS){
        const key = FILTER_FIELDS[field_idx]
        initialParams[key] = filterParams[key];
      }
      dispatch({
        type: "SET_FILTERS",
        payload: initialParams
      })
    }
  }, [initialized])


  useEffect(() => {
    console.log("loaded", loaded);
    if(loaded){
      updateSearchParams(filterState)
    }
  }, [filterState])

/*
  useEffect(() => {

    if(loaded){
      // searchParams
      console.log("search params changed");
    }
  }, [searchParams])
  */

  useEffect(() => {
    if(Object.keys(tags).length === 0){
      return;
    }
    console.log('tags changed')
    let res = [];
    tags.forEach((tag, index) => {
      if(index > 10){
        return;
      }
      res.push(
        <li key={tag.id}>
          {tag.name}: {tag.count}
        </li>
      );
    });
    setResults(res)
  }, [tags])

  /*
  useEffect(() => {
    if( allTagsList?.length > 0){
      console.log("allTagsList", allTagsList.length)
    }
  }, [allTagsList])
  */

  const handleSelectChange = (e) => {
    let selectedValues = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
    if(selectedValues[0] === ''){
      selectedValues = []
    }
    dispatch({
      type: "HANDLE SELECT CHANGE",
      field: e.target.name,
      payload: selectedValues
    })
  }

  const submitFilter = () => {
    // let formData = {...filterState}
    // filter(formData);
  }

  /*const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    submitFilter();
  };*/

  const handleTagChange = (e, value) => {
    // setSearchParams({tagId: value})
    // TODO: set tag
    goToExploreTag(value.id)
  }

  const updateSearchParams = (params) => {
    setSearchParams(params)
  }
  
  
  return (
    <div style={{flexGrow:1}}>
      { initialized &&
        <form>
          <Grid container spacing={1} direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid item>
              {/* Topic Search */}
              { allTagsList?.length &&
                <TagSearch 
                  options={allTagsList}  
                  selected={selectedTag || null} 
                  onChange={handleTagChange}
                />
              }
            </Grid>
             {/* Demographic filter */}
            <Grid item>
              <SingleSelect 
                label={'Countries'}
                name='countries' 
                defaultName='All countries'
                options={categories.country} ignore={[0]}
                field={filterState.countries} onChange={handleSelectChange} />
            </Grid>
            <Grid item>
              <SingleSelect 
                label={'Age Range'} 
                name='ageRange' 
                defaultName='All generations'
                options={AGE_RANGE_OPTIONS.map(r => r.key )} field={filterState.ageRange} onChange={handleSelectChange} />
            </Grid>
            <Grid item>
              <SingleSelect 
                label={'Tag Source'} 
                name='taxonomies' 
                defaultName='All tag sources'
                options={categories.taxonomies} ignore={[0]}
                field={filterState.taxonomies} 
                onChange={handleSelectChange}
              />
            </Grid>
          </Grid>

          {/* Tags */}
          {/* filtering && 
            <div>filtering...</div>
          }
          { !filtering && results &&
            <>
              <h3>{tags.length} Tags</h3>
              <ul>
                {results}
              </ul>
              <hr />
              <h3>{selectedCount} DINs</h3>
            </>
          */}

          
          {/* Title */}
          {/* <h3>Most Prevalent Tags</h3> */}

          {/* Stats - Tags/DINs */}
          {/* <div>
            5,439 Tags<br/>
            62,383 DINs
          </div> */}
        </form>
      }
    
    
    </div>
  )
}