import SparkMD5 from 'spark-md5';
import _ from 'lodash';

/**
 * 
 * @param {int} tagId 
 * @param {Object} filterParams 
 * @returns string
 */
export function makeUrlHash (tagId, filterParams) {
  console.log('filterParams', filterParams);
  console.log('tagId', tagId);
  const countryId = filterParams.countries[0] + 1 || 0;
  const ageRangeId = filterParams.ageRange[0] + 1 || 0;

  let buffer = new ArrayBuffer(32);
  let byteArray16 = new Uint16Array(buffer);
  let byteArray8 = new Uint8Array(buffer, 2, 2);
  byteArray16[0] = Number(countryId) & 0xffff;
  byteArray8[0] = Number(ageRangeId);
  byteArray8[1] = Number(tagId) + 1;

  // const hashAlgorithm = new SparkMD5.ArrayBuffer();`
  const hash = SparkMD5.ArrayBuffer.hash(buffer);
  return hash;
}

function isInteger(value) {
  return /^\d+$/.test(value);
}


export function searchParamsToObject (searchParams) {
  const arrayKeys = ["countries", "ageRange", "taxonomies", "growthOps"];
  let paramObject = {
    "countries": [],
    "ageRange": [],
    "taxonomies": [],
    "growthOps": []
  };

  const searchParamList = searchParams.entries();
  for (const entry of searchParamList) {
    let [key, value] = entry;
    key = isInteger(key) ? Number(key) : key;
    value = isInteger(value) ? Number(value) : value;
    if(_.indexOf(arrayKeys, key) > -1){
      // value = [value];
      if(key in paramObject){
        paramObject[key].push(value)
      } else {
        paramObject[key] = [value]
      }
    } else {
      paramObject[key] = value;
    }
  }
  return paramObject;
}

export function getDefinedSearchParams(searchParams) {
  // Return list of search params that are not undefined or empty arrays
  let definedParams = {}
  for (const [key, value] of Object.entries(searchParams)) {
    if(value === undefined) {
      continue;
    }
    if(Array.isArray(value) && value.length === 0){
      continue;
    }
    definedParams[key] = value;
  }
  return definedParams;
}