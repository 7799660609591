import { Chip, IconButton } from "@mui/material";
import { styled } from "@mui/system";

const HeaderEl = styled('div')`
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  & h2 {
    font-size: 11.25em;
    margin: 0;
    transition: all .3s ease-out;

    &.small {
      font-size: 3.0em;
    }
  }
`

export default function Header({text, small}) {
  

  return(
    <HeaderEl>
      <Chip label="STRENGTH" color="primary" />
      <h2 className={small ? 'small': ''}>{text}</h2>
      
    </HeaderEl>
  )
}