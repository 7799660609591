import * as React from 'react'
const DataContext = React.createContext()

function dataReducer(state, action) {
  
  switch (action.type) {
    case 'startCalculate': {
      return {
        loading: true,
        srcNum: action.payload,
        fibNum: undefined
      }
    }
    case 'calculated': {
      return {
        ...state,
        loading: false,
        fibNum: action.payload
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

function FibProvider({children}) {
  const [state, dispatch] = React.useReducer(dataReducer, 
    {
      srcNum: 1,
      fibNum: 0
    }
  )
  const value = {state, dispatch}
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

function useCalculate() {
  const context = React.useContext(DataContext)
  const { 
    dispatch
  } = context;

  const runWorker = (num) => {
    console.log('run worker', num);
    dispatch({type: 'startCalculate', payload: num})
    const worker = new window.Worker("/fib-worker.js");
    
    
    worker.postMessage({ num: num });
    worker.onerror = (err) => err;
    worker.onmessage = (e) => {
      const { time, fibNum } = e.data;
      console.log('set fib number', fibNum);
      dispatch({type:'calculated', payload: fibNum})
      worker.terminate();
    };
  };

  if (context === undefined) {
    throw new Error('useCalculate must be within a FibProvider') 
  }
  return {...context, runWorker}
}

export {FibProvider, useCalculate}