import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/system';

function getStyles(name, selectValues, theme) {
  return {
    fontWeight:
      (!selectValues || selectValues.indexOf(name)) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const CustomSelect = styled(Select)(({theme}) => ({
  // background: '#ff0000'
  // 'border-color': '#ffffff'
  // '&:before': {
  //     borderColor: 'white',
  // },
  // '&:after': {
  //     borderColor: 'white',
  // },
  // '&:not(.Mui-disabled):hover::before': {
  //     borderColor: 'white',
  // },
  // icon: {
  //     fill: 'white',
  // },
  // background: '#ff0000',
  color: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
    
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    }
  },
  borderRadius: '20px',
  root: {
      color: 'white',
  },
}))

export default function SingleSelect({
  id, name,
  field, onChange,
  defaultName = 'None',
  label = 'my label',
  options = ['option 1', 'option 2', 'option 3'],
  ignore=[]
}) {
  
  const theme = useTheme();
  let menuItems = [];
  options.forEach((item, i) => {
    if(ignore.indexOf(i) < 0 ){
      menuItems.push({
        key: item,
        item: item,
        value: Number(i)
     })
    }
  })

  console.log(menuItems);
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      {/* <InputLabel id="demo-select-small">{label}</InputLabel> */}
      <CustomSelect
        labelId="demo-select-small"
        id="demo-select-small"
        value={field}
        // label={label}
        name={name}
        onChange={onChange}
        variant="outlined"
        displayEmpty
      >
        <MenuItem value="">
          <em>{defaultName}</em>
        </MenuItem>
        {menuItems.map((item) => (
          <MenuItem
            key={item.key} value={item.value}
            style={getStyles(name, field, theme)}
          >
            {item.item}
          </MenuItem>
        ))}
        {/* {options.map((item, i) => (
            <MenuItem
              key={item} value={i}
              style={getStyles(name, field, theme)}
            >
              {item}
            </MenuItem>
          ))} */}
      </CustomSelect>
    </FormControl>
  );
}
