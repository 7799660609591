import { List, ListItem } from '@mui/material'
import Title from '../Title'

export default function StatTable({title}) {
  return (
    <>
      <Title>{title}</Title>
      <List>
        <ListItem>1. United States</ListItem>
        <ListItem>2. India</ListItem>
        <ListItem>3. Brazil</ListItem>
      </List>
    </>
  )
}